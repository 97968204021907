import React from 'react'
import './Navbar.css';
import Contact from './Contact';
import {BrowserRouter as Router, Route, Routes , Link} from 'react-router-dom';


export default function Navbar() {
  return (
    <div>

<nav class="navbar">
  <div class="container">
    <a class="navbar-brand" href="/">
      <img src="logo.png" alt="Bootstrap" width="120" height="70"/>

    <span>BONDING BONES</span>
    </a>
  </div>
</nav>

      {/* <div>

        <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <a class="navbar-brand" href="#">Navbar</a>
          <div className="container-fluid">
            <img src="logo.png" height={30} />
           
           
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <Link to ="/">
            <h1 id="mainName" className='nav-brand'>BONDING BONES</h1>
            </Link>
                {/* <li className="nav-item-1">
                  <Link className="nav-link active" aria-current="page" to="/">Home</Link>
                </li> */}
                {/* <li className="nav-item-2">
                  <Link className="nav-link" to="/contact">Contact</Link>
                </li> */}
              {/* </ul>
          </div>
        </nav>
      </div> */} 
    </div>
  )
}
