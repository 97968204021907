import React from 'react'
import {BrowserRouter as Router, Route, Routes , Link} from 'react-router-dom';

export default function Home(
    
) {
   
    return (
        <div>
            <div id="id">
                <div id='divtext'>
                    <h1 id="text">Adoption & Breeding <br />At One Place </h1>

                     
                    <a href='https://play.google.com/' target='_blank'><img src="googleplay.png" height="250" /></a>
                    
                    <Link to="/contact">
                        <button id="con">Contact Us</button>
                    </Link>
                    

                </div>
                <div>
                    <img id="Dog-img" src="Component 1.png" height="550" />
                </div>

            </div>




        </div>
    )
}